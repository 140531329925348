<template>
  <div
    class="meeting px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full pb-30">
        <TitleComponent
          class="py-8"
          title="October 2024 Meeting"
          size="large"
        />
        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              ATTENDEES
            </h2>
            <p class="mb-4">
              Below is a list of some of the entities represented at the
              meeting:
            </p>
            <ul class="list-disc p-light ml-10">
              <li>Armtec Defense</li>
              <li>Autoliv</li>
              <li>
                BAM: Federal Institute for Materials Research and Testing /
                German National Laboratory
              </li>
              <li>BATF / National Center for Explosives Training & Research</li>
              <li>Brigham Young University (BYU)</li>
              <li>
                Canadian Explosive Research Laboratory / Natural Resources
                Canada (CERL)
              </li>
              <li>
                Convenor of WG6 of CEN/TC 321 "Explosives" (Berlin, Germany)
              </li>
              <li>
                Department of Homeland Security (DHS) / Transportation Security
                Lab
              </li>
              <li>Dyno Nobel</li>
              <li>EPC Groupe</li>
              <li>Federal Bureau of Investigation (FBI)</li>
              <li>
                French National Institute for Industrial Environment and Risks
                (INERIS)
              </li>
              <li>Grucci</li>
              <li>Hill-Air Force Base</li>
              <li>Idaho National Laboratory (INL)</li>
              <li>
                Institut national de l'environnement industriel et des risques
                (INERIS, France)
              </li>
              <li>Lawrence Livermore National Laboratory (LLNL)</li>
              <li>Los Alamos National Laboratory (LANL)</li>
              <li>Military Industries Corporation (MIC, Saudi Arabia)</li>
              <li>National Aeronautics and Space Administration (NASA)</li>
              <li>Naval Ordnance Safety and Security Activity (NOSSA)</li>
              <li>Naval Research Laboratory (NRL)</li>
              <li>
                Naval Surface Warfare Center - Indian Head Division (NSWC IHD)
              </li>
              <li>NAVORD - Navy</li>
              <li>
                New Mexico Tech: Energetic Materials Research and Testing Center
                (EMRTC)
              </li>
              <li>
                Northrop Grumman Laboratories: ABL, Bacchus, Elkton, Endina,
                Promontory
              </li>
              <li>
                Oklahoma State University - Center for Health Sciences (OK
                State)
              </li>
              <li>OLIN - Winchester: Illinois, Lake City</li>
              <li>Safety Management Services, Inc.</li>
              <li>Sandia National Laboratories: Albuquerque, Livermore</li>
              <li>Signature Science, LLC</li>
              <li>
                Sporting Arms and Ammunition Manufacturers’ Institute (SAAMI),
                DG Advisor, LLC. (DGA)
              </li>
              <li>Thales Australia: Mulwala Land Systems</li>
              <li>
                TNO: Netherlands Organization for Applied Scientific Research /
                Netherlands National Laboratory
              </li>
              <li>Tooele Army Depot Laboratory</li>
              <li>UK Ministry of Defense</li>
              <li>U.S. Army RDECOM-ARDEC - Picatinny</li>
              <li>Vista Outdoor</li>
            </ul>
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <div class="mb-6">
              <h2
                class="mb-6"
                style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
              >
                MEETING PROCEEDINGS
              </h2>
              <div
                style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
                class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
                role="tabpanel"
              >
                <div class="panel-heading pl-6">
                  <h4
                    class="panel-title toggle"
                    style="position:relative;"
                    data-fontsize="16"
                    data-lineheight="22"
                  >
                    <a
                      @click="showContent = showContent === 1 ? false : 1"
                      :class="{ open: showContent === 1 }"
                      style="cursor:pointer;"
                      aria-expanded="false"
                      aria-selected="false"
                      aria-controls="811950d795ab93cb4"
                      role="tab"
                      data-toggle="collapse"
                    >
                      <span
                        class="fusion-toggle-icon-wrapper"
                        aria-hidden="true"
                      >
                        <i class="fa-fusion-box"></i>
                      </span>
                      <span
                        class="fusion-toggle-heading font-bold"
                        style="color:#ef9a3d;"
                        >2024 Meeting Proceedings</span
                      >
                    </a>
                  </h4>
                </div>
                <transition name="fade">
                  <div
                    id="811950d795ab93cb4"
                    class="panel-collapse collapse"
                    v-if="showContent === 1"
                  >
                    <div
                      class="flex flex-col panel-body toggle-content fusion-clearfix pt-6"
                    >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F001%20%26%20002%20-%20Welcome%20and%20Review%20of%20ET%20Users%20Group%20Charter%20-%20Kirt%20Sasser%20-%20SMS.pdf?alt=media&token=b8a0db37-ef01-41f9-afbb-1ad44eff940a"
                        >001 & 002 - Welcome and Review of ET Users Group
                        Charter - Kirt Sasser - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F002%20-%20An%20Overview%20of%20Blast%20Pressure%20Quantification%20from%20an%20R%26D%20Perspective%20-%20Dennis%20Rickman%20-%20ERDC.pdf?alt=media&token=0bc5bc09-e378-43d5-9167-23d63fe63aa2"
                        >002 - An Overview of Blast Pressure Quantification from
                        an R&D Perspective - Dennis Rickman - ERDC</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F003%20ETUG%20Round%20Robin%20Summary%20-%20Kirt%20Sasser%20-%20SMS.pdf?alt=media&token=c0bb7177-3ffa-479a-b7f4-55de91dabb9a"
                        >003 ETUG Round Robin Summary - Kirt Sasser - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F006%20-%20The%20Henkin%20Test%20Revisited%20-%20Jason%20Phillips%20-%20SNL.pdf?alt=media&token=a9b5c2ec-1ae8-4078-88e5-7a8471459712"
                        >006 - The Henkin Test Revisited - Jason Phillips -
                        SNL</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F007%20-%20Measuring%20DSC%20Data%20on%20LLM-105%20Materials%20-%20Alexander%20Gash%20-%20LLNL.pdf?alt=media&token=f970972a-ec6d-42fe-bc3f-9af39c990e4f"
                        >007 - Measuring DSC Data on LLM-105 Materials -
                        Alexander Gash - LLNL</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F008%20-%20DSC%20Round%20Robin%20Results%20HMX%20and%20PETN%20Analysis%20-%20Nicole%20Anderson%20-%20LLNL.pdf?alt=media&token=49fea0b9-aad0-4440-ae7a-01b74a3c5303"
                        >008 - DSC Round Robin Results HMX and PETN Analysis -
                        Nicole Anderson - LLNL</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F009%20-%20Tin%20Calibration%20for%20DSC%20-%20Nicole%20Anderson%20-%20LLNL.pdf?alt=media&token=c25a20f5-4596-4736-9986-85e0b1c5c5d0"
                        >009 - Tin Calibration for DSC - Nicole Anderson -
                        LLNL</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F010%20-%20Round%20Robin%20DSC%20Results%20with%20Pan-Type%20Comparative%20Analysis%20-%20Dr.%20Shannon%20Creegan%20-%20US%20Naval%20Research%20Laboratory.pdf?alt=media&token=e974cd4b-b154-446a-a7d4-48480a15fa5d"
                        >010 - Round Robin DSC Results with Pan-Type Comparative
                        Analysis - Dr. Shannon Creegan - US NRL</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F011%20-%202024%20ETUG%20DSC%20RR%20Update%20-%20Jack%20Zarbock%20-%20SMS.pdf?alt=media&token=98c8d6ad-b741-4935-9f69-452356c6fdc3"
                        >011 - 2024 ETUG DSC RR Update - Jack Zarbock - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F012%20-%20Koenen%20RR%20ICG%20and%20UN%20EWG%20Update%20-%20Bob%20Ford%20-%20SMS.pdf?alt=media&token=361291e6-d9a1-44c0-90cc-36061e3fecdd"
                        >012 - Koenen RR ICG and UN EWG Update - Bob Ford -
                        SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F013%20-%20IPS_2024-07-16_Findings%20from%20Thermite%20Research%20-%20Troy%20Gardner%20-%20SMS.pdf?alt=media&token=3a6b70bb-849c-43ac-b583-3c459780e03a"
                        >013 - IPS_2024-07-16_Findings from Thermite Research -
                        Troy Gardner - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F014%20-%20Accurate%20Reaction%20Detection%20for%20Sensitivity%20Testing%20of%20Explosives%20-%20Bob%20Ford%20-%20SMS.pdf?alt=media&token=885284f2-455c-4ee4-84b6-ef56e8320af0"
                        >014 - Accurate Reaction Detection for Sensitivity
                        Testing of Explosives - Bob Ford - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F015%20-%20Challenges%20with%20BAM%20Friction%20Testing%20at%20LANL%20-%20Patricia%20Huestis%20-%20LANL.pdf?alt=media&token=fcceddbd-97af-42e2-a65a-1ce24f7e4aed"
                        >015 - Challenges with BAM Friction Testing at LANL -
                        Patricia Huestis - LANL</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F017%20-%20Improvement%20of%20Drop-Hammer%20Impact%20Testing%20for%20Safety%20Assessment%20of%20Aged%20High%20Explosives%20-%20Batikan%20Koroglu%20-%20LLNL.pdf?alt=media&token=04c2b4ec-f687-4f7f-9d7c-81c0f1f2222c"
                        >017 - Improvement of Drop-Hammer Impact Testing for
                        Safety Assessment of Aged High Explosives - Batikan
                        Koroglu - LLNL</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F018%20-%20History%20of%20ETUG%20Member%20Labs%20-%20Boyd%20Hill%20-%20Northrop%20Grumman.pdf?alt=media&token=cb32ab58-1c2c-42fb-9485-a231805f145f"
                        >018 - History of ETUG Member Labs - Boyd Hill -
                        Northrop Grumman</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F019%20-%20Revised%20In-Process%20Classification%20Protocol%20-%20Troy%20Gardner%20-%20SMS.pdf?alt=media&token=1ab65d4d-a05a-48d2-ba82-1acb6ad245c7"
                        >019 - Revised In-Process Classification Protocol - Troy
                        Gardner - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F020%20-%20Ineris%20Pyrotechnic%20Activities%20and%20Recent%20Topic%20of%20Interest%20-%20Mary%20Dean%20-%20Ineris.pdf?alt=media&token=36b711a0-f1e3-4ff2-a780-f2801314713e"
                        >020 - Ineris Pyrotechnic Activities and Recent Topic of
                        Interest - Mary Dean - Ineris</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F021%20-%20Baseline%20Discussion%20of%20Electrical%20Classifications%20for%20Explosive%20Operation%20-%20Bob%20Ford%20-%20SMS.pdf?alt=media&token=0c8da011-7731-4e15-aa55-7aa5d307a24d"
                        >021 - Baseline Discussion of Electrical Classifications
                        for Explosive Operation - Bob Ford - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F022%20-%20Electrical%20Classification%20Labs%20and%20Temp%20-%20Bob%20Ford%20-%20SMS.pdf?alt=media&token=66bb67f4-3e33-4be9-b60b-103e56517c54"
                        >022 - Electrical Classification Labs and Temp - Bob
                        Ford - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F023%20-%20Evaluation%20of%20Hazardous%20Explosives%20Facilities%20-%20an%20Algorithmic%20Approach%20-%20Michael%20Lake%20-%20INL.pdf?alt=media&token=550d63ff-4200-4398-a1bd-8d50e9b29bdd"
                        >023 - Evaluation of Hazardous Explosives Facilities -
                        an Algorithmic Approach - Michael Lake - INL</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F024%20-%20Promontory%20Dust%20Hazards%20Analysis%20and%20Dust%20Explosibility%20Testing%20-%20Jacob%20Bauman%20-%20Northrop%20Grumman.pdf?alt=media&token=6bc967ec-d614-4e9d-bfb5-b0e0ba4dd9c8"
                        >024 - Promontory Dust Hazards Analysis and Dust
                        Explosibility Testing - Jacob Bauman - Northrop
                        Grumman</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F027%20-%20Overview%20of%20IVM%20Enhancements%20-%20Kirt%20Sasser%20-%20SMS.pdf?alt=media&token=25fda3f9-4565-4424-a8b8-89dd8cc7bfaa"
                        >027 - Overview of IVM Enhancements - Kirt Sasser -
                        SMS</a
                      >
                    </div>
                  </div>
                </transition>
              </div>
              <p class="p-light">
                These files are for participants of the ET Users Group. As such
                please consider the information as ‘approved for internal use
                only. Feel free to distribute this information within your
                company or group as needed. If individuals or organizations
                outside your company or group desire access to this information,
                please
                <router-link class="link" to="/contact-us"
                  >Contact Us</router-link
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss">
.meeting {
  .fusion-button.button-1 {
    border-radius: 0px;
  }

  .panel-title a .fa-fusion-box:before {
    position: absolute;
    left: -2rem;
    top: 2px;
    content: url("../../assets/plus-solid.svg");
    filter: invert(70%) sepia(90%) saturate(601%) hue-rotate(326deg)
      brightness(94%) contrast(100%);
  }

  .panel-title a.open .fa-fusion-box:before {
    content: url("../../assets/minus-solid.svg");
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .btn:hover {
    background-color: #464d5f !important;
  }
}
</style>

[
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F001%20%26%20002%20-%20Welcome%20and%20Review%20of%20ET%20Users%20Group%20Charter%20-%20Robert%20Ford%20-%20SMS.pdf?alt=media&token=cc540d69-fb07-4321-af98-d7cdd3b12fd6">001 & 002 - Welcome and Review of ET Users Group Charter - Robert Ford - SMS</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F003%20-%20UN%20EWG%20Round%20Robin%20Results%202023%20Koenen%20-%20Tom%20Pleva%20-%20EMRTC.pdf?alt=media&token=d7affb58-93d9-4094-b651-9fe5ac466d11">003 - UN EWG Round Robin Results 2023 Koenen - Tom Pleva - EMRTC</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F006%20-%20UN%20EWG%20International%20Koenen%20Round-Robin%20Test%20Results%20-%20Jack%20Zarbock%20-%20SMS.pdf?alt=media&token=d45ce4e9-d2d0-4926-94d3-bd86d999b89a">006 - UN EWG International Koenen Round-Robin Test Results - Jack Zarbock - SMS</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F007%20-%20UN%20EWG%20International%20Koenen%20Round-Robin%20Test%20Results%20-%20Bob%20Ford%20-%20SMS.pdf?alt=media&token=4d7af827-02f3-4492-9c05-225074488ad4">007 - UN EWG International Koenen Round-Robin Test Results - Bob Ford - SMS</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F007a%20-%20UN%20EWG%20International%20Koenen%20Round-Robin%20Test%20Results%20-%20Kirt%20Sasser%20-%20SMS.pdf?alt=media&token=a48c5f62-02af-4541-be38-3854dcc3e46c">007a - UN EWG International Koenen Round-Robin Test Results - Kirt Sasser - SMS</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F008%20-%20History%20of%20Hazards%20Analysis%20and%20HA%20Techniques%20-%20Boyd%20Hill%20-%20Northrop%20Grumman.pdf?alt=media&token=da4e0c34-b3a5-4af3-bc4e-cae9966b076f">008 - History of Hazards Analysis and HA Techniques - Boyd Hill - Northrop Grumman</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F009%20-%20Mitigating%20Fire%20Incidents%20Exploring%20Potentially%20Reduced%20Flash%20Points%20in%20Manufacturing%20Mixtures%20-%20Clint%20Guymon%20-%20BYU.pdf?alt=media&token=6b3d6276-9f5d-4d04-9b7f-b599de1063c3">009 - Mitigating Fire Incidents Exploring Potentially Reduced Flash Points in Manufacturing Mixtures - Clint Guymon - BYU</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F010%20-%20AORC%20Guideline%20for%20Electrical%20Classification%20of%20Explosives%20Operating%20Buildings%20-%20Scott%20Genta%20-%20SMS.pdf?alt=media&token=c9ef52e9-bae1-4c1d-bd89-2b6df3e93faa">010 - AORC Guideline for Electrical Classification of Explosives Operating Buildings - Scott Genta - SMS</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F010a%20-%20AORC%20Guideline%20for%20Electrical%20Classification%20of%20Explosives%20Operating%20Buildings%20-%20SMS.pdf?alt=media&token=b431b6be-c9a9-42cd-9a1e-07ca656f33c2">010a - AORC Guideline for Electrical Classification of Explosives Operating Buildings - SMS</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F011%20-%20NFPA%20495%20Electrical%20Section%20-%20Larry%20Lyon%20-%20SMS.pdf?alt=media&token=463ea2b9-fd4d-4679-9d5e-ba2a39231e21">011 - NFPA 495 Electrical Section - Larry Lyon - SMS</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F012%20-%20HD%201.3%20Overpressure%20Modeling%20-%20Jacob%20Burrell%20-%20SMS.pdf?alt=media&token=b605379f-7565-40c7-9702-c855e0a63219">012 - HD 1.3 Overpressure Modeling - Jacob Burrell - SMS</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F013%20-%20European%20Standards%20Revisions%20Overview%20-%20Lionel%20Aufauvre%20-%20EPC%20Groupe.pdf?alt=media&token=15e1b794-4999-4210-95ce-96fe449dc2cb">013 - European Standards Revisions Overview - Lionel Aufauvre - EPC Groupe</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F014%20-%20The%20Effect%20of%20Sample%20Size%20on%20Impact%20Sensitivity%20of%20PETN%20-%20Koroglu%20Batikan%20-%20LLNL.pdf?alt=media&token=fa540919-371d-4eb7-bce9-7e048c55245e">014 - The Effect of Sample Size on Impact Sensitivity of PETN - Koroglu Batikan - LLNL</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F015%20-%20Evaluation%20fo%20an%20Experimental%20Expanded%20Analogy%20Test%20Methodology%20for%20Small%20Arms%20Primers%20-%20Alan%20Serven%20-%20SAAMI.pdf?alt=media&token=96cb6781-267a-4945-b888-8bc21611a2f0">015 - Evaluation fo an Experimental Expanded Analogy Test Methodology for Small Arms Primers - Alan Serven - SAAMI</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F016%20-%20Sensitivity%20Test%20Equipment%20Maintenance%20-%20Rusty%20Christensen%20-%20SMS.pdf?alt=media&token=f003a6b1-9c28-4a43-8365-c3f7f2803361">016 - Sensitivity Test Equipment Maintenance - Rusty Christensen - SMS</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F017%20-%20Thermal%20Decomposition%20of%20Isotopically%20Labeled%20TATB%20-%20Nicole%20Anderson%20-%20LLNL.pdf?alt=media&token=ba9aaebc-a9c6-47e9-9cb9-6447d73b1061">017 - Thermal Decomposition of Isotopically Labeled TATB - Nicole Anderson - LLNL</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F018%20-%20DSC%20Round%20Robin%20Results%20PETN%20and%20HMX%20Testing%20-%20Jason%20Phillips%20-%20Sandia.pdf?alt=media&token=474df6b9-a582-4fda-a868-e591d5ecca8d">018 - DSC Round Robin Results PETN and HMX Testing - Jason Phillips - Sandia</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F019%20-%20Promontory%202023%20DSC%20Round%20Robin%20Results%20-%20Jacob%20Bauman%20-%20Northrop%20Grumman.pdf?alt=media&token=52de48f7-2321-4dc1-91cf-f44d8e4553d7">019 - Promontory 2023 DSC Round Robin Results - Jacob Bauman - Northrop Grumman</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F020%20-%20SMS%20DSC%20Round%20Robin%20-%20Mark%20Frum%20-%20Northrop%20Grumman.pdf?alt=media&token=249b6f9a-d5b0-45b3-b388-c66edd20693e">020 - SMS DSC Round Robin - Mark Frum - Northrop Grumman</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F021%20-%20SMS%20DSC%20Laboratory%20Test%20Results%20-%20Jack%20Zarbock.pdf?alt=media&token=350fbeaf-39ef-415f-bae8-1f7b93edca1e">021 - SMS DSC Laboratory Test Results - Jack Zarbock</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F022%20-%202022%20DSC%20Round%20Robin%20Results%20-%20Jack%20Zarbock%20-%20SMS.pdf?alt=media&token=2fe67b51-f2b9-4455-a81d-332a70fdf030">022 - 2022 DSC Round Robin Results - Jack Zarbock - SMS</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F023%20-%20Considerations%20for%20Smokeless%20Powders%20Factors%20Affecting%20Reaction%20Severity%20-%20Troy%20Gardner%20-%20SMS.pdf?alt=media&token=70b691b1-47e6-4472-9e01-a519d9bd11ea">023 - Considerations for Smokeless Powders Factors Affecting Reaction Severity - Troy Gardner - SMS</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F024%20-%20Hazard%20Testing%20of%20Wetted%20Smokeless%20Propellant%20-%20Alan%20Serven%20-%20SAAMI.pdf?alt=media&token=6139ea2b-623b-46be-94d4-93da99a7f717">024 - Hazard Testing of Wetted Smokeless Propellant - Alan Serven - SAAMI</a>
]
