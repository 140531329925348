<template>
  <div
    class="charter px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-1/6"></div>
      <div class="w-full md:w-2/3">
        <p class="text-2xl mt-4 text-center" style="color: #7e8890;">
          October 8th and 9th
        </p>
        <TitleComponent
          class="py-8"
          title="16th Annual ETUG Meeting (2024)"
          size="large"
        />
        <p style="text-align: left; font-size: 20px; color: #7e8890;">
          The Explosives Testing Users Group™ is focused on the standardization
          of in-process classification characterization testing. We are making
          substantial progress in the standardization of sensitivity testing
          methods, reaction detection, and round robin testing protocol. We look
          forward to another productive meeting with participating industry,
          national, and international laboratories.
        </p>
        <h2 class="text-xl font-bold my-4" style="color: #7e8890;">
          Topics to be addressed include:
        </h2>
        <ul
          class="list-disc ml-8"
          style="font-size:20px;line-height:34px; color: #7e8890;"
        >
          <li class="fusion-li-item">
            Round Robin Test Results
          </li>
          <li class="fusion-li-item">
            Sensitivity Testing Advancements & Lessons Learned
          </li>
          <li class="fusion-li-item">
            ETUG Test Methods Matrix Update
          </li>
          <li class="fusion-li-item">
            Application of In-Process Test Results for Process Risk Assessments
          </li>
        </ul>
        <h2 class="text-xl font-bold my-4" style="color: #7e8890;">
          Meeting Details:
        </h2>
        <p style="text-align: left; font-size: 20px; color: #7e8890;">
          We’ll meet on October 8th and October 9th, 2024 at the Sheraton Salt
          Lake City Hotel. Further info on the
          <a
            class="mb-2"
            style="color:#ef9a3d;"
            target="_blank"
            href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2024%2F2024%20ETUG%20Flyer%20.pdf?alt=media&token=267a08c1-647b-429b-a546-05358da318bb"
            >Meeting Flyer.</a
          >
          <!--Please contact us at 801-567-0456 or jzarbock@smsenergetics.com if you
          have any questions.-->
          Registration for the ET User’s Group 2024 Meeting is now closed.
          Please contact us at 801-567-0456 or jzarbock@smsenergetics.com if you
          have any questions.
        </p>
        <!--
        <router-link
          to="/meeting-registration"
          class="fusion-button button-flat fusion-button-default-size button-default button-1 fusion-button-default-span fusion-button-default-type"
        >
          <button
            class="text-white font-bold py-2 px-4 rounded w-full btn mt-4"
            style="background-color:#f09a3e;"
          >
            Register
          </button>
        </router-link>-->
      </div>
      <div class="w-full md:w-1/6"></div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  methods: {
    // createICS() {
    //   var icsMSG =
    //     "BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//ET Users Group - ECPv5.1.0//NONSGML v1.0//EN\nCALSCALE:GREGORIAN\nMETHOD:PUBLISH\nX-WR-CALNAME:ET Users Group\nX-ORIGINAL-URL:https://www.etusersgroup.org\nX-WR-CALDESC:Events for ET Users Group\nBEGIN:VTIMEZONE\nTZID:America/Denver\nBEGIN:DAYLIGHT\nTZOFFSETFROM:-0700\nTZOFFSETTO:-0600\nTZNAME:MDT\nDTSTART:20210314T090000\nEND:DAYLIGHT\nBEGIN:STANDARD\nTZOFFSETFROM:-0600\nTZOFFSETTO:-0700\nTZNAME:MST\nDTSTART:20211107T080000\nEND:STANDARD\nEND:VTIMEZONE\nBEGIN:VEVENT\nDTSTART;VALUE=DATE:20211012\nDTEND;VALUE=DATE:20211014\nDTSTAMP:20210415T032743\nCREATED:20160316T184710Z\nLAST-MODIFIED:20201207T200847Z\nUID:610-1633478400-1633651199@www.etusersgroup.org\nSUMMARY:13th Annual ETUG Meeting (2021)\nDESCRIPTION:The Explosives Testing Users Group is focused on the standardization of in-process classification characterization testing. We are making substantial progress in the standardization of sensitivity testing methods\, reaction detection\, and round robin testing protocol. We look forward to another productive meeting with participating industry\, national\, and international laboratories. \nTopics to be addressed include:\n\nSensitivity Testing Round Robin Results \n\nSensitivity Testing Advancements & Lessons Learned \n\nETUG Test Methods Matrix Update \n\nApplication of In-Process Test Results for Process Risk Assessments \n\nMeeting Details:\nThis year’s meeting will be held at Hotel Park City. We’ll meet on October 12th and 13th. Details forthcoming. Please contact us at 801-567-0456 if you have any questions. \n\nURL:https://www.etusersgroup.org/event/2021-13th-annual-etug-annual-meeting/\nLOCATION:UT\, United States\nCATEGORIES:Event\nEND:VEVENT\nEND:VCALENDAR";
    //   window.open("data:text/calendar;charset=utf8," + escape(icsMSG));
    // }
  }
};
</script>
